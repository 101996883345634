import sdk from './sdk';
import { isEqual } from 'lodash';

// IE11 needs URL to end with a slash '/'

const post_families = async (clinician_id, duplicate_of=null) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + '/families/';
    let response = await sdk.request('post', url, { clinician_id, duplicate_of });

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const post_uuid = async () => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + '/uuid/';
    let response = await sdk.request('post', url);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const get_member_memberid = async (member_id) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + '/members/' +  member_id + '/';
    let response = await sdk.request('get', url);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const get_member_memberid_parents = async (member_id) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + '/members/' +  member_id + '/parents/';
    let response = await sdk.request('get', url);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const get_member_memberid_grandparents = async (member_id) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + '/members/' +  member_id + '/grandparents/';
    let response = await sdk.request('get', url);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const post_member = async (payload) => {
  try {
    let url = process.env.REACT_APP_ROOT_API_URL + '/members/';
    let response = await sdk.request('post', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const patch_member_memberid = async (member_id, payload) => {
  try {
    let url = process.env.REACT_APP_ROOT_API_URL + `/members/${member_id}/`;
    let response = await sdk.request('patch', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

// const delete_member_memberid = async (member_id) => {
//   try {
//
//     let url = process.env.REACT_APP_ROOT_API_URL + '/members/' +  member_id + '/';
//     let response = await sdk.request('delete', url);
//
//     let data = response.data;
//     return data;
//
//   } catch (err) {
//     throw sdk.parse_error_message(err)
//   }
// }

const check_delete_member_memberid = async (member_id) => {
  try {
    let url = process.env.REACT_APP_ROOT_API_URL + '/members/' +  member_id + '/check_delete_by_clinician/';
    let response = await sdk.request('post', url);

    let data = response.data;
    return data;
  } catch (err) {
    throw err.response.data
  }
}

const post_biological_parents = async (payload) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + '/biological_parents/';
    let response = await sdk.request('post', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const post_childs = async (payload) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + '/childs/';
    let response = await sdk.request('post', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const get_members_memberid_children = async (member_id, partner_id) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + `/members/${member_id}/children/?partner_id=` + partner_id;
    let response = await sdk.request('get', url);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const get_clinician_clinicianid_probands = async (clinician_id, search=null, page=1) => {
  try {

    let base_url = process.env.REACT_APP_ROOT_API_URL + `/clinician/${clinician_id}/probands/`;

    let url = base_url
    if(search && typeof(search) === "string") {
      search = search.trim();

      // Search for each term separately
      let terms = search.split(" ");
      terms = terms.filter((term) => {
        if(term) return term;
      });

      let results = [];
      let first = true;
      for(let term of terms) {
        url = base_url + `?search=${term}`;

        let response = await sdk.request('get', url, {page: page});
        let data = response.data;

        // Compare results - if value exists in current accepted pool and in new set of data, keep. Otherwise, remove
        if(first) {
          results.push(...data);
          first = false;
        }
        else results = results.filter(element => {
          for(let item of data) {
            if(isEqual(element, item)) return true;
          }
          return false;
        });
      }

      return results;
    } else {
      let response = await sdk.request('get', url, {page: page});
      return response.data;
    }

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

// const delete_parents_top_level = async (member_id) => {
//   try {
//     let url = process.env.REACT_APP_ROOT_API_URL + '/member/' + member_id + '/delete_parents/';
//     let response = await sdk.request('post', url);
//
//     let data = response.data;
//     return data;
//   }
//   catch (err) {
//     throw sdk.parse_error_message(err)
//   }
// }

const get_member_memberid_partners = async (member_id) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + '/members/' +  member_id + '/partners/';
    let response = await sdk.request('get', url);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const post_partner_relationship = async (payload) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + `/partners/`;
    let response = await sdk.request('post', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const get_partner_relationship = async (father_id, mother_id) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + `/partners/get/?father_id=${father_id}&mother_id=${mother_id}`;
    let response = await sdk.request('get', url);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const patch_partner_relationship_partnerid = async (partner_id, payload) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + `/partners/${partner_id}/`;
    let response = await sdk.request('patch', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const delete_partner_relationship_partnerid = async (payload) => {
  try {
    let url = process.env.REACT_APP_ROOT_API_URL + `/partners/delete_partner_record/`;
    let response = await sdk.request('post', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const post_twin_new = async (payload) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + `/twin/new/`;
    let response = await sdk.request('post', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const post_edit_twin_type = async (payload) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + `/twin/editTwinType/`;
    let response = await sdk.request('post', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const post_add_twin = async (payload) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + `/twin/addTwin/`;
    let response = await sdk.request('post', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const delete_twin_set = async (twin_id) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + `/twin/${twin_id}/`;
    let response = await sdk.request('delete', url);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const get_twin = async (father_id, mother_id) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + `/twin/?father_id=${father_id}&mother_id=${mother_id}`;
    let response = await sdk.request('get', url);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

export const get_family = async (family_id) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + '/families/' + family_id + '/';
    let response = await sdk.request('get', url);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const delete_family = async (payload) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + '/families/delete_family/';
    let response = await sdk.request('delete', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const validate_patient_id = async (payload) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + '/members/validate_patient_id/';
    let response = await sdk.request('post', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const query_probands = async (clinician_id, payload) => {
  let search = payload.search;

  try {

    let base_url = process.env.REACT_APP_ROOT_API_URL + `/clinician/${clinician_id}/query_probands/`;
    let url = base_url;
    let response = await sdk.request('get', url, payload);

    return response.data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

export const post_parents = async (payload) => {
  try {
    let url = process.env.REACT_APP_ROOT_API_URL + '/member/add_parents/';
    let response = await sdk.request('post', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

export const update_parents = async (payload) => {
  try {
    let url = process.env.REACT_APP_ROOT_API_URL + '/member/update_parent_info/';
    let response = await sdk.request('post', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

export const post_member_dial_code = async (payload)=>{
  try {
    let url = process.env.REACT_APP_ROOT_API_URL + '/member/add_dial_code/';
    let response = await sdk.request('post', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

export const react_flow_pedigree_layout = async (payload)=>{
  try {
    let url = process.env.REACT_APP_ROOT_API_URL + '/flux/layout/';
    let response = await sdk.request('post', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

export const flux_pdf_generator = async (payload) =>{

  try {
    let url = process.env.REACT_APP_ROOT_API_URL + '/flux/pdf/';
    let response = await sdk.request('post', url, payload);
    let data = response.data;

    return data
  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

export const flux_image_generator = async(payload) =>{
  try {
    let url = process.env.REACT_APP_ROOT_API_URL + '/flux/png/';
    let response = await sdk.request('post', url, payload);
    let data = response.data;
    return data
  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

export const flux_risk_pdf_generator = async(payload) =>{
  try {
    let url = process.env.REACT_APP_ROOT_API_URL + '/flux/pdf_report/';
    let response = await sdk.request('post', url, payload);
    let data = response.data;
    return data
  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

export const react_flow_pedigree_layout_dom = async (payload)=>{
  try {
    let url = process.env.REACT_APP_ROOT_API_URL + '/flux/domlayout/';
    let response = await sdk.request('post', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

export const react_flow_pedigree_layout_dom_v2 = async (payload)=>{
  try {
    let url = process.env.REACT_APP_ROOT_API_URL + '/flux/simpleLayout/';
    let response = await sdk.request('post', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

export const react_flow_save_layout_data = async (payload) => {
  try {
    let url = process.env.REACT_APP_ROOT_API_URL + '/flux/saveLayoutData/';
    let response = await sdk.request('post', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

export const react_flow_get_layout_data = async (payload) => {
  try {
    let url = process.env.REACT_APP_ROOT_API_URL + '/flux/getLayoutData/';
    let response = await sdk.request('post', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

export const get_emr_patients = async (payload, clinician_id)=>{

try {

  let base_url = process.env.REACT_APP_ROOT_API_URL + `/clinician/${clinician_id}/emr_search/`;
  let url = base_url;
  let response = await sdk.request('get', url, payload);

  return response.data;

} catch (err) {
  throw sdk.parse_error_message(err)
  }
}

export const update_patient_id_from_emr = async (payload)=>{
  try{

    let base_url = process.env.REACT_APP_ROOT_API_URL + `/clinician/update_emr_patient_id/`;
    let url = base_url;
    let response = await sdk.request('post', url, payload);

    return response.data;

  } catch (err) {
    throw sdk.parse_error_message(err)
    }
}

export const get_hidden_disease_colors_post = async (payload) => {
  try{
    let url = process.env.REACT_APP_ROOT_API_URL + '/hiddendiseasecolors/get_hidden_disease_colors/'
    let response = await sdk.request('post', url, payload);
    return response.data;
  }
  catch (err){
    throw sdk.parse_error_message(err);
  }
}

export const save_hidden_disease_colors_post = async (payload) => {
  try{
    let url = process.env.REACT_APP_ROOT_API_URL + '/hiddendiseasecolors/save_hidden_disease_colors/'
    let response = await sdk.request('post', url, payload);
    return response.data;
  }
  catch (err){
    throw sdk.parse_error_message(err);
  }
}

export const remove_hidden_disease_colors_post = async (payload) => {
  try{
    let url = process.env.REACT_APP_ROOT_API_URL + '/hiddendiseasecolors/remove_hidden_disease_colors/'
    let response = await sdk.request('post', url, payload);
    return response.data;
  }
  catch (err){
    throw sdk.parse_error_message(err);
  }
}
export const get_family_pedigree_notes = async (family_id) => {
  try{
    let url = process.env.REACT_APP_ROOT_API_URL + '/families/get_family_pedigree_notes/'
    let response = await sdk.request('get', url, {family_id: family_id});
    return response.data;
  }
  catch (err){
    throw sdk.parse_error_message(err);
  }

}
export const save_family_pedigree_notes = async (payload) => {
  try{
    let url = process.env.REACT_APP_ROOT_API_URL + '/families/save_family_pedigree_notes/'
    let response = await sdk.request('post', url, payload);
    return response.data;
  }
  catch (err){
    throw sdk.parse_error_message(err);
  }
}

export const save_family_clinicians = async (payload) => {
  try{
    let url = process.env.REACT_APP_ROOT_API_URL + '/families/save_family_clinicians/'
    let response = await sdk.request('post', url, payload);
    return response.data;
  }
  catch (err){
    throw sdk.parse_error_message(err);
  }
}

export const invite_patient = async (payload) => {
  try{
    let url = process.env.REACT_APP_ROOT_API_URL + '/patient_account/invite_patient/'
    console.log(payload)
    let response = await sdk.request('post', url, payload);
    return response.data;
  }
  catch (err){
    throw sdk.parse_error_message(err);
  }
}

export const clin_to_patient_url = async (payload) => {
  try{
    let url = process.env.REACT_APP_ROOT_API_URL + '/patient_account/clin_to_patient_url'
    console.log(payload)
    let response = await sdk.request('get', url, payload);
    console.log(response)
    return response.data;
  }
  catch (err){
    console.log(err)
    throw sdk.parse_error_message(err);
  }
}

export const get_ancestry_list = async () => {
  try{
    let url = process.env.REACT_APP_ROOT_API_URL + '/ancestry/'
    let response = await sdk.request('get', url);
    return response.data;
  }
  catch (err){
    throw sdk.parse_error_message(err);
  }
}

export const update_member_ancestry = async (payload) => {
  try{
    let url = process.env.REACT_APP_ROOT_API_URL + `/member_ancestry/update_member_ancestry/`
    let response = await sdk.request('post', url, payload);
    return response.data;
  }
  catch (err){
    throw sdk.parse_error_message(err);
  }
}

export const update_adopted_info = async (payload) => {
  try{
    let url = process.env.REACT_APP_ROOT_API_URL + `/member/update_adopted_info/`
    let response = await sdk.request('post', url, payload);
    return response.data;
  }
  catch (err){
    throw sdk.parse_error_message(err);
  }
}

const can_delete_member = async (member_id) => {
  const payload = {member_id: member_id};

  try {

    let base_url = process.env.REACT_APP_ROOT_API_URL + `/member/deletable/`;
    let url = base_url;
    let response = await sdk.request('get', url, payload);

    return response.data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const bulk_delete_members = async (member_id, pre_qualified_member_ids) => {
  const payload = {
    member_id: member_id,
    pre_qualified_member_ids: pre_qualified_member_ids
  };

  try {

    let base_url = process.env.REACT_APP_ROOT_API_URL + `/member/bulk_delete/`;
    let url = base_url;
    let response = await sdk.request('post', url, payload);

    return response.data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const clone_with_new_proband = async (payload) => {

  try {

    let url = process.env.REACT_APP_ROOT_API_URL + `/family/clone_with_new_proband/`;
    let response = await sdk.request('post', url, payload);

    return response.data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const link_with_existing_pedigree = async (payload) => {

  try {

    let url = process.env.REACT_APP_ROOT_API_URL + `/family/link_with_existing_pedigree/`;
    let response = await sdk.request('post', url, payload);

    return response.data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const unlink_pedigrees = async (payload) => {

  try {

    let url = process.env.REACT_APP_ROOT_API_URL + `/family/unlink_pedigrees/`;
    let response = await sdk.request('post', url, payload);

    return response.data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const query_list_of_proband_options = async (payload) => {

  try {

    let url = process.env.REACT_APP_ROOT_API_URL + `/family/query_list_of_proband_options/`;
    let response = await sdk.request('post', url, payload);

    return response.data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const get_patient_status_list = async () =>{
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + `/account/patient_onboarding_status/`;
    let response = await sdk.request('get', url);
    return response.data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const query_search = async (payload) => {

  try {
    let url = process.env.REACT_APP_ROOT_API_URL + `/query/search/`;
    let response = await sdk.request('post', url, payload);

    return response.data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const get_query_results = async (payload, signal) => {

  try {

    let url = process.env.REACT_APP_ROOT_API_URL + `/query/getQueryResults/`;
    let response = await sdk.request('post', url, payload, "", "", {signal});

    return response.data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const get_family_relationships = async (proband_id) => {

  let payload = {
    "proband_id": proband_id
  }

  try{

    let url =  process.env.REACT_APP_ROOT_API_URL + `/family/get_family_relationships/`;
    let response = await sdk.request('get', url, payload);

    return response.data;

  }catch(err){
    throw sdk.parse_error_message(err)
  }
}

const get_family_relation_tree = async (family_id, target_id) => {

  let payload = {
    "family_id": family_id,
    "target_id": target_id
  }

  try{

    let url =  process.env.REACT_APP_ROOT_API_URL + `/family/get_relation_tree/`;
    let response = await sdk.request('get', url, payload);

    return response.data;

  }catch(err){
    throw sdk.parse_error_message(err)
  }
}

const post_donor = async (payload) => {
  try {
    let url = process.env.REACT_APP_ROOT_API_URL + `/donor/add_donor/`;
    let response = await sdk.request('post', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const delete_donor = async (id) => {
  try {
    let url = process.env.REACT_APP_ROOT_API_URL + `/donor/${id}/`;
    let response = await sdk.request('delete', url);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

export default {
  parse_error_message: sdk.parse_error_message,
  post_uuid,
  post_families,
  get_member_memberid,
  get_member_memberid_parents,
  get_member_memberid_grandparents,
  get_members_memberid_children,
  get_member_memberid_partners,
  post_member,
  patch_member_memberid,
  // delete_member_memberid,
  check_delete_member_memberid,
  post_biological_parents,
  post_childs,
  get_clinician_clinicianid_probands,
  post_partner_relationship,
  get_partner_relationship,
  patch_partner_relationship_partnerid,
  post_twin_new,
  post_add_twin,
  delete_twin_set,
  get_twin,
  get_family,
  delete_family,
  validate_patient_id,
  post_parents,
  update_parents,
  query_probands,
  post_member_dial_code,
  get_emr_patients,
  update_patient_id_from_emr,
  get_hidden_disease_colors_post,
  save_hidden_disease_colors_post,
  remove_hidden_disease_colors_post,
  // delete_parents_top_level,
  react_flow_pedigree_layout,
  react_flow_pedigree_layout_dom,
  react_flow_pedigree_layout_dom_v2,
  save_family_pedigree_notes,
  get_family_pedigree_notes,
  flux_pdf_generator,
  flux_image_generator,
  react_flow_save_layout_data,
  react_flow_get_layout_data,
  save_family_clinicians,
  flux_risk_pdf_generator,
  invite_patient,
  clin_to_patient_url,
  update_member_ancestry,
  get_ancestry_list,
  can_delete_member,
  bulk_delete_members,
  clone_with_new_proband,
  link_with_existing_pedigree,
  unlink_pedigrees,
  query_list_of_proband_options,
  post_edit_twin_type,
  get_patient_status_list,
  query_search,
  get_query_results,
  get_family_relationships,
  get_family_relation_tree,
  delete_partner_relationship_partnerid,
  post_donor,
  delete_donor,
  update_adopted_info
}


const HORIZONTAL = Symbol("horiz");
const VERTICAL = Symbol('vert');
const ARCH = Symbol('arch')
const OTHER = Symbol('other');

const PERSON = Symbol('person');
const CONNECTOR = Symbol("connector");
const HOOK_SIZE = 10;
class Node {
	constructor(id) {
		this.id = id;
	}
	getID() {
		return this.id;
	}

}

// Node 50px
class Node_Line_Store {
	constructor() {
		this.nodes = {}; //setup the node map
		this.people = {};
		this.lines = {};
		this.line_ID_List = [];
		this.edges = {};
		this.spousesTemp = {};
		this.spouseMap = {};
		this.reverseSpouse = {};
		console.log("node store created");
	}

	addNode(node, nodeType, x, y) {
		// let id = node.id;
		let id = node;
		if (this.nodes[id] === undefined) { // new node
		}
		else {
		}
		let data = { "node": node, "x": x, "y": y };
		if (nodeType === "person") {
			this.people[id] = data;
		}

		this.nodes[id] = data;
		// console.log(this.nodes);
	}
	getNode(node_id) {
		let result = null;
		if (node_id in this.nodes) {
			result = this.nodes[node_id];
		}
		return result;
	}

	//{x1:45, y1:45, x2:52, y2:60, type="", id="1234"}
	// addConnection(nodea, nodeb, lineArray)

	getNodeLocation(node_id) {

		if (node_id in this.nodes) {
			return this.nodes[node_id];
		}
		else {
			return null;
		}
	}

	addSpouseConnector(the_node, connector_node) {
		the_node = the_node.toString();
		// let connectorList = [];
		// if (the_node in this.spouseMap)
		// {
		// 	connectorList = this.spouseMap[the_node];
		// }
		// if (!connectorList.includes(connector_node))
		// {
		// 	connectorList.push(connector_node);
		// }
		// this.spouseMap[the_node] = connectorList;
		this.mapToList(this.spouseMap, the_node, connector_node)

		// let other = [];
		// if (connector_node in this.reverseSpouse)
		// {
		// 	other = this.reverseSpouse[connector_node];
		// }
		// if (! (other.includes(the_node)))
		// {
		// other.push(the_node);
		// this.reverseSpouse[connector_node] = other;
		// }
		this.mapToList(this.reverseSpouse, connector_node, the_node);
	}

	mapToList(map, key, value) {
		let other = [];

		if (key in map) {
			other = map[key];
		}

		if (!(other.includes(value))) {
			other.push(value);
		}
		map[key] = other;
	}

	getSpouse(dummy) {
		if (dummy in this.reverseSpouse) {
			return this.reverseSpouse[dummy];
		}
		else {
			return [];
		}
	}

	getSpouseConnector(nodea) {
		// console.log(this.spouseMap);
		if (nodea in this.spouseMap) {
			let otherList = this.spouseMap[nodea];
			if (otherList.length == 1) {
				let other_Node = otherList[0];
				return this.nodes[other_Node];
			}
			else {
				return null;
			}

		}
		else {
			// console.log(this.spouseMap);
			return null;
		}
	}

	getClosestSiblingToDonor(siblings, donor_node_position) {
		let people = Object.values(this.people);
		let siblings_list = people.filter(person => siblings.find(sibling => sibling.id == person.node)).map(person => person.x);

		let closest = siblings_list.reduce(function(prev, curr) {
		return (Math.abs(curr - donor_node_position.x) < Math.abs(prev - donor_node_position.x) ? curr : prev);
		});

		return closest;
	}

	updateEdge(nodes) {
		// console.log("Updating edge "+id + "("+ x1 + "," + y1 + "->" + x2 + "," + y2 + ")");
		// this.datastore.addConnection(id1,id2);
		let idlist = nodes["edgelist"];
		for (const element of idlist) {
			if (!this.line_ID_List.includes(element)) {
				this.line_ID_List.push(element);
			}
			let aPath = document.getElementById(element);
			if (aPath !== null) {
				// console.log(aPath);
				// console.log(aPath.nodeName);
				if (aPath && aPath.pathLength) {
					let length = aPath.pathLength;
					if (element.nodeName === "path") {
						let halfpoint = aPath.getPointAtLength(length / 2);
						// console.log("Element " + element + " Len" + length + " Half Point" + halfpoint );
					}
				}
			}
			// var path = svg.createPath();
			// var pathNode = svg.path(path.move(100, 200).curveC([[200,
			//     100, 300, 0, 400, 100], [500, 200, 600, 300, 700,
			//     200], [800, 100, 900, 100, 900, 100]]));
			// pathNode.
		};

		// console.log(this.line_ID_List);
		let TheType = nodes["type"];
		if (TheType === "Spouse") {
			this.addSpouseConnector(nodes["src"], nodes["target"]);

		}
		else {
			this.addConnection(nodes["src"], nodes["target"]);
		}
	}

	getHorizonalLines(source_x1, source_y1, dest_x2, dest_y2) {

		let intersection = [];

		for (const element of this.line_ID_List) {
			if (element !== null) {
				let aPath = document.getElementById(element);
				if (aPath !== null) {
					// console.log(aPath.nodeName);

					if (aPath.nodeName === "line") {
						let x1 = parseInt(aPath.getAttribute("x1"));
						let y1 = parseInt(aPath.getAttribute("y1"));
						let x2 = parseInt(aPath.getAttribute("x2"));
						let y2 = parseInt(aPath.getAttribute("y2"));
						if (y1 === y2) {
							if (this.between(source_x1, x1, x2)) {
								// if ((y1 > v_y1 && y1 < v_y2) || (y1 < v_y1 && y1 > v_y2))
								if (this.between(y1, source_y1, dest_y2)) {
									// console.log("(" + x1 + "," + y1 + ") (" + x2 + "," + y2 + ")");
									// console.log("(" + v_x1 + "," + v_y1 + ") (" + v_x2 + "," + v_y2 + ")");
									intersection.push({ "x": source_x1, "y": y1 });
								}
							}
						}
					}
					else if (aPath.nodeName == "path") {
						let len = aPath.getTotalLength();
						let start = aPath.getPointAtLength(0);
						let x1 = start.x;
						// let y1 = start.y;
						let end = aPath.getPointAtLength(len);
						let x2 = end.x;
						if (this.between(source_x1, x1, x2)) {
							let count = 0;
							let MAX = 20;
							let startDistance = 0;
							let endDistance = len;
							let versoina = true;
							let bestMid = null;
							if (versoina) {

								let mid = (startDistance + endDistance) / 2;
								let midpoint = aPath.getPointAtLength(mid);
								count = 0;
								while (Math.abs(midpoint.x - source_x1) > 5) {
									if (midpoint.x > source_x1) {
										endDistance = mid;
									}
									else if (midpoint.x < source_x1) {
										startDistance = mid;
									}
									else if (midpoint.x === source_x1) {
										bestMid = midpoint;
										break;
									}
									count += 1;
									if (count > MAX)
										break;
									mid = (startDistance + endDistance) / 2;
									midpoint = aPath.getPointAtLength(mid);
									bestMid = midpoint;
								}
								if (bestMid) {
									if (this.between_EQ(bestMid.y, source_y1, dest_y2)) {
										let res = { "x": bestMid.x, "y": bestMid.y };
										intersection.push(res);
									}
								}
							}

						}
					}
				}

			}

		}

		return intersection;
	}

	distance(x1, y1, x2, y2) {
		let xdiff = (x1 - x2);
		let ydiff = (y1 - y2);
		let sum = xdiff * xdiff + ydiff * ydiff;
		return Math.sqrt(sum);

	}

	splitVerticalLine(x1, y1, y2, pointList) {
		let result = {};
		let lines = [];
		let hooks = [];
		if (y1 > y2) {
			let temp = y1;
			y1 = y2;
			y2 = temp;
		}
		let yArray = this.getSortedY(pointList);
		yArray = this.filterPoints(yArray, y1, y2);
		for (let i = 0; i < yArray.length; i++) {
			let currenty = yArray[i];
			let newLine = { "x1": x1, "y1": y1, "x2": x1, "y2": currenty - HOOK_SIZE };
			lines.push(newLine);
			let hook = { "x1": x1, "y1": currenty - HOOK_SIZE, "x2": x1, "y2": currenty + HOOK_SIZE };
			hooks.push(hook);
			y1 = currenty + HOOK_SIZE;
		}
		//add list line
		let newLine = { "x1": x1, "y1": y1, "x2": x1, "y2": y2 };
		lines.push(newLine);
		result["hooks"] = hooks;
		result["lines"] = lines;
		return result;
	}

	filterPoints(y_Array, minY, maxY) {
		let result = [];
		let bound = 5
		let previous = null;
		for (let i = 0; i < y_Array.length; i++) {
			let item = y_Array[i];
			let needToAdd = true;
			if (item < minY + bound) {
				needToAdd = false;
			}
			if (item > maxY - bound) {
				needToAdd = false;
			}
			if (previous == null) {
				previous = item;
			}
			else {
				if ((item - previous) < 20) // merge two hooks that are close together
				{
					result.pop();
					result.push((item + previous) / 2);
					needToAdd = false;
				}
			}
			previous = item;
			if (needToAdd) {
				result.push(item);
			}
		}
		// if (result.length < y_Array.length)
		// {
		// 	console.log(result);
		// 	console.log(y_Array);
		// }
		return result;
	}

	getSortedY(pointList) {
		let result = [];
		for (let i = 0; i < pointList.length; i++) {
			let temp = pointList[i];
			let y = temp["y"];
			if (!result.includes(y)) {
				result.push(y);
			}
		}

		result.sort(function (a, b) {
			return a - b;
		});
		return result;
	}


	between(a, q, s) {
		let result = false;
		if ((a < q && a > s) || (a > q && a < s)) {
			result = true;
		}
		return result;
	}

	between_EQ(a, q, s) {
		let result = false;
		if ((a <= q && a >= s) || (a >= q && a <= s)) {
			result = true;
		}
		return result;
	}



	addConnection(nodea, nodeb) {
		let nodeList = []
		if (nodea in this.edges) {
			nodeList = this.edges[nodea];
		}
		if (!(nodeList.includes(nodeb))) {
			nodeList.push(nodeb);
			this.edges[nodea] = nodeList;
		}
		nodeList = []
		if (nodeb in this.edges) {
			nodeList = this.edges[nodeb];
		}
		if (!nodeList.includes(nodea)) {
			nodeList.push(nodea);
			this.edges[nodeb] = nodeList;
		}



	}
	//check if two spouses have nodes between

	checkInersection(nodea, nodeb) {
		return this.getInersection(nodea.id, nodeb.id);
	}



	getIntersection(node_a_id, node_b_id) {
		let data = this.nodes[node_a_id];
		if (!data) return []
		let n1 = data["node"];
		let x1 = data["x"];
		let y1 = data["y"];

		data = this.nodes[node_b_id];
		let n2 = data["node"];
		let x2 = data["x"];
		let y2 = data["y"];

		let between = [];
		for (const [key, element] of Object.entries(this.nodes)) {

			if (key !== n1.id && key !== n2.id) //don't match these nodes
			{
				let other = element["node"];
				let x3 = element["x"];
				let y3 = element["y"];
				if (Math.abs(y3 - y2) < 50 || Math.abs(y3 - y1) < 50) // see if they are close
				{
					if ((x3 < x1 && x3 > x2) || (x3 > x1 && x3 < x2)) {
						if (key in this.people) {
							between.push(other);
						}
					}
				}
			}
		}

		return between;
		// console.log(n1+" " + x1 + ","  + y1);
		// console.log(between);
	}
	ping() {
		console.log("here");
	}
	getType(typeString) {
		if (typeString === HORIZONTAL.toString()) {
			return HORIZONTAL;
		}
		else if (typeString === VERTICAL.toString()) {
			return VERTICAL;
		}
		else if (typeString === ARCH.toString()) {
			return ARCH;
		}
		else if (typeString === OTHER.toString()) {
			return OTHER;
		}
	}
	getNodeType(typeString) {
		console.log("The type");
		console.log(typeString);
		if (typeString === PERSON.toString()) {

			return PERSON;
		}
		else {
			return CONNECTOR;
		}

	}
}
export default Node_Line_Store;
// module.exports.init = function () {
// 	console.log('hi');
// 	let n = new Node_Line_Store();
// 	// n.checkInersection();
// 	let n0 = new Node("zero");
// 	n.addNode(n0,PERSON,15,20);
// 	for (let i = 0; i < 10; i++)
// 	{
// 		let n1 = new Node("a" + i)
// 		n.addNode(n1,PERSON ,i * 10 ,20);
// 	}
// 	let n2 = new Node('b');
// 	n.addNode(n2, PERSON,20,20);
// 	// n.addNode(n2, 30,20);
// 	n.checkInersection(n0,n2);
//   };
